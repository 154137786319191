<template>
    <div>
        <span class="label label-warning d-inline-block mr-2 pl-2 pr-2 mb-0">
            Up to <strong>{{maxStorageZ}} GB</strong> 
        </span>
    </div>
</template>
<script>
    export default {
        props: {
            element: {
                type: Object,
                default() {
                    return null;
                },
            }
        },
        watch: {
            element() {
                this.maxStorageZ = parseInt(this.element.serviceDetails);
            }
        },
        data() {
            return {
                maxStorageZ: 100
            };
        },
        created() {
            this.maxStorageZ = parseInt(this.element.serviceDetails);
        }
    };
</script>